import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

import useScript from "../service/useScript"
import useDeviceDetect from "../service/useDeviceDetect"

const Ebrochure = ({ data }) => {
   
    const metaTag = data?.allNodeTemplatePages?.edges?.[0]?.node?.metatag || []
    useScript("/assets/js/e-brochure.js")
    const { isMobile } = useDeviceDetect()
    const component = data?.allNodeTemplatePages?.edges[0]?.node?.relationships?.components;
    const list = data?.allTaxonomyTermDepartment?.edges;
    const banner = Array.isArray(component) ? component[0] : [];
    const bannerTitle = banner?.title || "";
    const bannerSubtitle = banner?.subtitle || "";
    const bannerImage = banner?.relationships?.bannerImage?.uri?.url
    const bannerImageAlt = banner?.bannerImage?.alt.length > 0 ? banner?.bannerImage?.alt : "Hinduja Hospital "
    const intro = Array.isArray(component) ? component[1] : [];
    const description = intro?.htmlText?.processed || "";
    const cards = Array.isArray(component) ? component[2]?.relationships?.field_components : []
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: [
                            "/assets/css/e-brochure.css"
                        ],
                    }}
                    tags={metaTag}
                />
                <main className="innerpage">
                    <section class="banner_section gradient_bg pt-15px">
                        <div class="Inner_banner_content left_content_top_space">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-7 col-md-6">
                                        <div class="banner_content">
                                            <div class="title_with_image">
                                                <h1>{bannerTitle}</h1>
                                                <img src="/assets/images/icons/book_an_appointment.svg" alt={bannerImageAlt}/>
                                            </div>
                                            <div class="description">
                                                <p>{bannerSubtitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-6">
                                        <img src={bannerImage} className="hero_image text-center" alt={bannerImageAlt} />
                                        <span class="plus_icon">
                                            <img src="/assets/images/icons/plus_icon_herobanner_image.png" alt={bannerImageAlt} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="eBrochure-intro-txt">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <p dangerouslySetInnerHTML={{ __html: description }} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="section-bg">
                        <div class="eBrochure">
                            <div class="container">
                                <div class="row subDepartment filter-col">
                                    {
                                        cards.length > 0 ? cards.map((el, index) => {
                                            return (
                                                <div class="col-lg-3 col-md-4 box" title={el?.relationships?.field_department?.name ? el?.relationships?.field_department?.name : "hello"}>
                                                    <div class="card">
                                                        <div class="card-body text-center">
                                                            <p><strong>{el?.field_title}</strong><span class="depTxt" dangerouslySetInnerHTML={{ __html: el?.field_content?.processed }} /></p>
                                                        </div>
                                                        <div class="dwnloadPdf text-center mb-3">
                                                            <a href={el?.relationships?.field_pdf_upload?.uri?.url} target="_blank"><img src="/assets/images/e-brochure/pdf-icn.png" />{el?.field_pdf_upload?.description}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    }
                                    <h3>Now, you can independently access information you need, anytime, anywhere, on-the-go!</h3>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export const query = graphql`
query healthBuzzNewsletter {
  allNodeTemplatePages(
    filter: {path: {alias: {regex: "/health-buzz-newsletter$/"}}}
  ) {
    edges {
      node {
        id
        title
        path {
          alias
        }
        metatag {
          attributes {
            content
            href
            name
            property
            rel
          }
        }
        relationships {
          components: field_component_type {
            __typename
            ...ParagraphBanner
            ...ParagraphHTMLText
            ... on paragraph__title_and_description_components {
              id
              relationships {
                field_components {
                  ... on paragraph__image_with_title_and_description {
                    id
                    field_title
                    field_pdf_upload {
                      description
                    }
                    field_content {
                      processed
                    }
                    relationships {
                      field_department {
                        name
                        id
                      }
                      field_pdf_upload {
                        uri {
                          value
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  allTaxonomyTermDepartment {
    edges {
      node {
        id
        name
      }
    }
  }
}
`
export default Ebrochure;